import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pl from './locales/pl.json';
import en from './locales/en.json';

// Sprawdzamy, czy jesteśmy w środowisku przeglądarki
const isWindow = typeof window !== 'undefined';

if (isWindow) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        pl: { translation: pl },
        en: { translation: en }
      },
      fallbackLng: 'pl',
      interpolation: {
        escapeValue: false
      },
      detection: {
        order: ['localStorage', 'navigator']
      }
    });
}

export default i18n;


// src/i18n/config.js
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

// import pl from './locales/pl.json';
// import en from './locales/en.json';

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     resources: {
//       pl: { translation: pl },
//       en: { translation: en }
//     },
//     fallbackLng: 'pl',
//     interpolation: {
//       escapeValue: false
//     },
//     react: {
//       useSuspense: true
//     }
//   });

// // Dodaj to do debugowania
// if (process.env.NODE_ENV === 'development') {
//   i18n.on('initialized', () => {
//     console.log('i18n initialized', i18n.language);
//     console.log('Available resources:', Object.keys(i18n.options.resources));
//   });

//   i18n.on('languageChanged', (lng) => {
//     console.log('Language changed to:', lng);
//   });
// }

// export default i18n;