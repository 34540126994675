/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n/config';
import "./src/styles/globals.css"

export const onClientEntry = () => {
  const script = document.createElement("script")
  script.src = "https://tally.so/widgets/embed.js"
  script.async = true
  document.body.appendChild(script)
}

export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>
    {element}
  </I18nextProvider>
);
